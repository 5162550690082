<template>
  <v-dialog
    scrollable
    :value="value"
    max-width="800"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    @keydown.esc="$emit('input', false)"
  >
    <person-edit-card
      :person="person"
      @close="$emit('input', false)"
      @save="(e) => $emit('save', e)"
      @delete="(e) => $emit('delete', e)"
    />
  </v-dialog>
</template>

<script>
import PersonEditCard from './PersonEditCard.vue'

export default {
  name: 'PersonEditDialog',
  components: { PersonEditCard },
  props: ['value', 'person'],
  data() {
    return {}
  },
}
</script>
