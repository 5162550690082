import isEmpty from 'lodash/isEmpty'
import { parseNumber, isValidNumber } from 'libphonenumber-js'

const MAIL_REGEX = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/

const ValidationMixin = {
  methods: {
    isValid(name) {
      const e = this.$refs[name]
      return !(e && e.hasError)
    },
  },
}

function required(value) {
  return !!value || 'Dieses Feld ist erforderlich'
}

function isEmail(value) {
  return !value || !!value.match(MAIL_REGEX) || 'Diese Email ist ungültig'
}

function isPhoneNumber(value) {
  const parsed = !!value && parseNumber(value, 'CH')
  return !value || (!value.match(/[a-zA-Z]/) && !isEmpty(parsed) && isValidNumber(parsed)) || 'Diese Telefonnummer ist ungültig'
}

function isAHVNumber(value) {
  // Implemented according to https://www.bsv.admin.ch/bsv/de/home/sozialversicherungen/ahv/grundlagen-gesetze/ahv-nummer.html
  if(!value) {
    return true
  }
  const ahvRegex = /^(756)\.?(\d{4})\.?(\d{4})\.?(\d{2})$/
  const match = value.match(ahvRegex)
  if (!match) {
    return 'Ungültige AHV Nummer'
  }
  const ahvString = match.slice(1).join('')


  // Checksum calculation according to EAN-13
  const sum = ahvString.split('').slice(0, -1).reverse().map((n, i) => n * (i % 2 === 0 ? 3 : 1)).reduce((sum, n) => sum + n, 0)
  const roundedUp = Math.ceil(sum / 10) * 10
  const checkDigit = roundedUp - sum
  return checkDigit === parseInt(ahvString.at(-1)) || 'Ungültige AHV Nummer'
}

export {
  ValidationMixin,
  required,
  isEmail,
  isPhoneNumber,
  isAHVNumber,
}
