<template>
  <edit-card
    @save="$emit('save', localPerson)"
    @delete="$emit('delete', localPerson)"
    @abort="$emit('close')"
    :can-save="formValid"
    :can-delete="canDelete"
  >
    <template slot="toolbar">
      <text-icon colorize class="mr-3">{{ localPerson.name }}</text-icon>
      <v-toolbar-title>{{
        localPerson.name || "Neuer Gast erfassen"
      }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click.stop="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>

    <template slot="content">
      <v-form v-model="formValid" ref="form">
        <div class="text-subtitle-1 mb-1 pt-1 font-weight-medium">Kontaktinformationen</div>
        <v-container fluid class="pa-0 pl-3">
          <v-row dense>
            <v-col cols="6" v-if="localPerson.type === 'guest'">
              <v-text-field
                ref="ifname"
                v-model="localPerson.first_name"
                label="Vorname"
                :rules="[rules.required]"
                required
                :hide-details="isValid('ifname')"
              />
            </v-col>
            <v-col cols="6" v-if="localPerson.type === 'guest'">
              <v-text-field
                v-model="localPerson.last_name"
                hide-details
                label="Nachname"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                ref="imail"
                label="Email"
                type="email"
                v-model="localPerson.email"
                :rules="[rules.isEmail]"
                :hide-details="isValid('imail')"
                validate-on-blur
              />
            </v-col>
            <v-col cols="6">
              <phone-text-field
                ref="iphone"
                label="Natel / Telefon"
                v-model="localPerson.phone"
                :rules="[rules.isPhoneNumber]"
                :hide-details="isValid('iphone')"
                validate-on-blur
              />
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                label="Adresse"
                v-model="localPerson.address"
                validate-on-blur
              />
            </v-col>

            <v-col cols="4" md="2">
              <v-text-field
                label="Postleitzahl"
                v-model="localPerson.zip_code"
                validate-on-blur
              />
            </v-col>

            <v-col cols="8" md="4">
              <v-text-field
                label="Ort"
                v-model="localPerson.city"
                validate-on-blur
              />
            </v-col>

            <v-col cols="6">
              <date-text-field
                v-model="localPerson.date_of_birth"
                hide-details
                label="Geburtstag"
              />
            </v-col>
          </v-row>
        </v-container>
        <p
          v-if="localPerson.type === 'member'"
          class="mt-4 mb-3 deep-orange--text text--darken-3"
        >
          Alle Felder werden von den SAC Daten überschrieben, falls vorhanden.
          Fehler müssen von den Mitglieder direkt beim Zentralverband korrigiert
          werden.
        </p>
        <div class="text-subtitle-1 mb-1 mt-3 font-weight-medium">Notfallkontakt</div>
        <v-container fluid class="pa-0 pl-3">
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                ref="iiname"
                v-model="localPerson.ice_name"
                hide-details
                label="Name"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                ref="iirel"
                v-model="localPerson.ice_relation"
                hide-details
                label="Beziehung"
              />
            </v-col>
            <v-col cols="12">
              <phone-text-field
                ref="iiphone"
                label="Natel / Telefon"
                v-model="localPerson.ice_phone"
                :rules="[rules.isPhoneNumber]"
                validate-on-blur
                :hide-details="isValid('iiphone')"
              />
            </v-col>
          </v-row>
        </v-container>
        <div class="text-subtitle-1 mb-1 mt-4 font-weight-medium">Bemerkungen</div>
        <v-container fluid class="pa-0 pl-3">
          <v-row dense>
            <v-col cols="12">
              <p>
                Achtung: Diese Informationen werden für alle Touren verwendet!
                Bitte keine Informationen im Bezug auf eine bestimmte Tour
                eintragen.
              </p>
              <v-textarea
                v-model="localPerson.notes"
                multi-line
                rows="4"
                hide-details
                solo
                placeholder="z.B. hat Auto, Halbtax/GA, ..."
              />
            </v-col>
          </v-row>
        </v-container>
        <div class="text-subtitle-1 mb-1 mt-3 font-weight-medium">J+S Daten</div>
        <v-container fluid class="pa-0 pl-3">
          <v-row dense>
            <v-col cols="12">
              <p class="mb-1">
                Die J+S Personennummer sowie die AHV oder PID Nummer erleichtern die Erfassung der Teilnehmer in der Nationalen Sport Datenbank (NDS).
                Diese Daten sind lediglich für Leiter und Teilnehmer im KiBe und der JO nötig.
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="localPerson.js_number"
                hide-details
                label="J+S Personennummer"
              />
            </v-col>
            <v-col md="6" class="hidden-lg-and-up"></v-col>
            <v-col cols="12" md="6">
              <v-text-field
                ref="r_js_ahv_number"
                v-model="localPerson.js_ahv_number"
                label="J+S AHV Nummer"
                :rules="[rules.isAHVNumber]"
                :hide-details="isValid('r_js_ahv_number')"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="J+S PEID"
                v-model="localPerson.js_peid"
                hide-details
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </edit-card>
</template>

<script>
import clone from 'lodash/clone'

import { ValidationMixin, required, isEmail, isPhoneNumber, isAHVNumber } from '@/validation'

import DateTextField from '@/components/fields/DateTextField'
import PhoneTextField from '@/components/fields/PhoneTextField'
import EditCard from '@/components/EditCard'

export default {
  name: 'PersonEditCard',
  components: { EditCard, DateTextField, PhoneTextField },
  mixins: [ValidationMixin],
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localPerson: clone(this.person),
      rules: { required, isEmail, isPhoneNumber, isAHVNumber },
      formValid: true,
    }
  },
  computed: {
    canDelete() {
      return this.localPerson.type === 'guest' && this.localPerson.id !== undefined
    },
  },
  watch: {
    person() {
      const person = clone(this.person)
      person.name = `${this.localPerson.first_name} ${this.localPerson.last_name}`.trim()
      this.localPerson = person
    },
  },
}
</script>
