<template>
  <v-dialog
    scrollable
    :value="value"
    max-width="800"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    @keydown.esc="$emit('input', false)"
  >
    <person-detail-card
      :person="person"
      @close="$emit('input', false)"
      @edit="$emit('edit')"
    />
  </v-dialog>
</template>

<script>
import PersonDetailCard from './PersonDetailCard.vue'

export default {
  name: 'PersonDetailDialog',
  components: { PersonDetailCard },
  props: ['value', 'person'],
  data() {
    return {}
  },
}
</script>
