<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat dense color="grey lighten-3">
        <v-text-field
          flat
          solo
          hide-no-data
          hide-details
          dense
          append-icon="mdi-magnify"
          label="Mitglied suchen..."
          v-model="searchInput"
        />
      </v-toolbar>
      <v-progress-linear v-if="!loaded" :indeterminate="true" />
      <div v-else-if="filteredMembers.length">
        <div>
          <person-tile
            v-for="item in paginatedMembers"
            :key="item.id"
            :person="item"
            @click="showDialog"
            @contentclick="showDetail(item)"
            @editclick="showEdit(item)"
          />
        </div>
        <div class="text-center">
          <v-divider />
          <v-pagination
            :length="numPages"
            :total-visible="$vuetify.breakpoint.smAndUp ? 7 : 5"
            v-model="page"
          />
        </div>
      </div>

      <v-card-text v-else>Keine Mitglieder gefunden.</v-card-text>
    </v-card>

    <person-detail-dialog
      v-model="showDetailDialog"
      :person="dialogItem"
      @edit="showDialog({ type: 'edit', id: dialogItem.id })"
    />

    <person-edit-dialog
      v-model="showEditDialog"
      :person="dialogItem"
      @save="saveMember"
    />
  </v-container>
</template>

<script>
import { mapState } from 'pinia'
import fuzzy from 'fuzzy'
import includes from 'lodash/includes'

import PersonTile from '@/components/person/PersonTile'
import PersonDetailDialog from '@/components/person/PersonDetailDialog.vue'
import PersonEditDialog from '@/components/person/PersonEditDialog.vue'

import { handleApiError, createNotification } from '@/utils.js'
import { usePersonStore } from '@/stores/person'

export default {
  name: 'Members',
  components: { PersonTile, PersonDetailDialog, PersonEditDialog },
  props: {},
  data() {
    return {
      searchInput: '',
      selected: [],
      all: false,
      loaded: false,
      showDetailDialog: false,
      showEditDialog: false,
      dialogItem: {},
      page: 1,
      perPage: 100,
    }
  },
  computed: {
    filteredMembers() {
      const inp = this.searchInput.trim()
      if (!inp) {
        return this.members
      }
      const matches = fuzzy.filter(inp, this.members, { extract: el => el.name })
      return matches.map(e => e.original)
    },
    numSelected() {
      return this.selected.length
    },
    numFiltered() {
      return this.filteredMembers.length
    },
    numPages() {
      return Math.ceil(this.filteredMembers.length / this.perPage)
    },
    paginatedMembers() {
      const offset = (this.page - 1) * this.perPage
      return this.filteredMembers.slice(offset, offset + this.perPage)
    },
    tbContentStyles() {
      const lstPos = this.$refs.mlist.$el.getBoundingClientRect().left
      const offset = Math.max(lstPos + 24, 55)
      return {
        position: 'absolute',
        left: offset + 'px',
      }
    },
    ...mapState(usePersonStore, { members: 'memberList' }),
  },
  watch: {
    filteredMembers() {
      this.selected = []
    },
    all(newVal) {
      if (newVal) {
        this.selected = this.filteredMembers
      } else {
        this.selected = []
      }
    },
    numPages(newVal) {
      if (newVal < this.page) {
        this.page = Math.max(newVal, 1)
      }
    },
  },
  mounted() {
    this.loaded = false
    usePersonStore().fetchPersons().then(() => {
      this.loaded = true
      this.$root.$emit('triggerScroll')
    }).catch(error => {
      handleApiError(error, 'Fehler beim Laden der Daten.')
    })
  },
  methods: {
    showDialog(event) {
      this.showEditDialog = false
      this.showDetailDialog = false
      const promise = usePersonStore().getPerson(event.id)
      if (event.type === 'detail') {
        promise
          .then(member => {
            this.dialogItem = member
            this.showDetailDialog = true
          })
          .catch(e => handleApiError(e, 'Fehler beim Laden der Daten'))
      } else if (event.type === 'edit') {
        promise
          .then(member => {
            this.dialogItem = member
            this.showEditDialog = true
          })
          .catch(e => handleApiError(e, 'Fehler beim Laden der Daten'))
      }
    },
    saveMember(member) {
      usePersonStore().updateOrCreate(member)
        .then(member => {
          createNotification('Mitglied gespeichert', 'success')
          this.showEditDialog = false
        })
        .catch(e => handleApiError(e, 'Fehler beim Speichern'))
    },
    isSelected(member) {
      return includes(this.selected, member)
    },
  },
}
</script>

