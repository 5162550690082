<template>
  <div
    class="d-flex align-center person-tile px-2 py-1"
    @click.stop="detailClick"
  >
    <!-- TODO: Funtional Components are slow within virtual scroller. Replace text-icon -->
    <text-icon colorize>{{ person.name }}</text-icon>
    <div class="row pl-3 person-info">
      <div class="person-primary col-sm-8 col-md-5 col-12 col">
        {{ person.name }}
      </div>
      <div class="person-secondary hidden-xs-only col-sm-4 col-md-3 col">
        {{ person.phone }}
      </div>
      <div class="person-secondary hidden-sm-and-down col-md-4 col">
        {{ person.email }}
      </div>
    </div>
    <v-btn icon><v-icon>mdi-pencil</v-icon></v-btn>
  </div>
</template>

<script>
export default {
  name: 'PersonTile',
  props: ['person'],
  methods: {
    detailClick() {
      this.$emit('click', { type: 'detail', id: this.person.id })
    },
    editClick() {
      this.$emit('click', { type: 'edit', id: this.person.id })
    },
  },
}
</script>

<style lang="scss">
.person-tile {
  &:hover {
    background-color: #fafafa;
  }
  .person-info {
    width: 100%;
    overflow: hidden;

    .person-primary {
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .person-secondary {
      font-size: 13px;
    }
  }
}
</style>
