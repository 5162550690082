import { AsYouType } from 'libphonenumber-js'

export default {
  name: 'PhoneTextField',
  functional: true,
  render(h, { data, children }) {
    // Add as-you-type formatting
    const onInput = data.on.input
    function updater(val) {
      const formatted = new AsYouType('CH').input(val) // eslint-disable-line
      onInput(formatted || val)
    }
    data.on.input = updater

    // Set the type to tel if nothing else explicitly given.
    if(!Object.prototype.hasOwnProperty.call(data.attrs, 'type')) {
      data.attrs.type = 'tel'
    }
    return h('v-text-field', data, children)
  },
}

