<template>
  <v-text-field
    :value="value"
    v-bind="$attrs"
    :required="required"
    :rules="validator"
    :tabindex="tabindex"
    lazy
    @input="(v) => $emit('input', v)"
    @blur.stop="atBlur"
  />
</template>

<script>

import { dateIsValid, dateDMYtoDDMMYYYY } from '@/utils/dates'

export default {
  name: 'DateTextField',
  components: {},
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    tabindex: {
      type: [Number, String],
      default: null,
    },
  },
  computed: {
    validator() {
      return [
        v => !v || this.isValid(v) || 'Kein gültiges Datum',
        ...(this.$attrs.rules || []),
      ]
    },
  },
  methods: {
    isEmpty(d) {
      return (d === null || d === undefined) ? true : !d.trim()
    },
    isValid(d) {
      return this.isEmpty(d) || dateIsValid(d)
    },
    atBlur(e) {
      const val = dateDMYtoDDMMYYYY(e.target.value)
      this.$emit('input', val)
      this.$emit('blur', val)
    },
  },
}
</script>
